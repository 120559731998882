import React from 'react';
import Link from '../atoms/link';

const Footer = ({
	pageDisclaimer = false
}) => {
	const pageDisclaimerContent = (pageDisclaimer) ? (
		<p>{pageDisclaimer}</p>
	) : null;

	return (
		<div className='co-footer'>
			<div className='co-footer__main'>
				<div className='l-container'>
					<div className='l-grid__row l-grid__middle-xs co-footer__logo-links'>
						<div className='l-grid__col-md co-footer__links--secondary'>
							<Link url='/privacy-policy/' classNames='co-footer__link'>
								Privacy Policy
							</Link>
							<Link url='/terms-and-services/' classNames='co-footer__link'>
								Terms &amp; Services
							</Link>
							<Link url='/contact-us/' classNames='co-footer__link'>
								Contact Us
							</Link>
							<Link url='/marketing-partners/' classNames='co-footer__link'>
								Marketing Partners
							</Link>
						</div>
					</div>
					<div className='l-grid__row'>
						<div className='l-grid__col-xs-12 l-spacing__m-t--24 co-footer__disclaimer'>
							{pageDisclaimerContent}
							<p>SunshineDebtSolutions.com does not provide debt relief services. SunshineDebtSolutions.com matches consumers with debt relief companies in our network. By submitting your information you agree to be contacted by our partner debt relief providers via phone, text messaging and / or email. Each partner will provide a proposal for debt negotiation services & may charge a fee for their service. Contact us directly for more details and to see exactly what you would qualify for. Results vary based on ability to save funds, complete all program terms & willingness of creditors to renegotiate.</p>
							<br />
							<p>Debt settlement is a negotiation process and therefore it is not possible to predict exact savings. Anyone considering bankruptcy should contact a bankruptcy attorney. Clients who make all their monthly program payments pay approximately 50% of their enrolled balance before fees, 68% to 75% including fees, over 24 to 48 months. Not all clients complete their program for various reasons, including their ability to save sufficient funds. Estimates based on prior results, which will vary based on specific circumstances. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume consumer debt, make monthly payments to creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states and our fees may vary from state to state. Please contact a tax professional to discuss tax consequences of settlement. Read and understand all program materials prior to enrollment, including potential adverse impact of credit rating. The use of debt settlement services will likely adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements obtained on your behalf resolve the entire account, including all accrued fees and interest.</p>
						</div>
					</div>
				</div>
			</div>
			<div className='co-footer__copyright'>
				<div className='l-container'>
					<div className='l-grid__row'>
						<div className='l-grid__col-xs'>
							<p>&copy; Copyright {new Date().getFullYear()} SunshineDebtSolutions. All rights reserved.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
